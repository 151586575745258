<template>
  <div class="p-wrap">
    <div class="p-header">
      <p-header-vue border spacer title="数电账户"> </p-header-vue>

      <p-select-vue :limit="2" :model="selectForm" :query-btn-auth="'RP-RP-CX'" :reset-btn-auth="'RP-RP-CZ'" class="p-select" label-width="100px" @reset="reset" @select="query">
        <el-form-item slot="1" label="所属组织">
          <organization-select :is-add-all="true" :model="record" @handleSelectAction="generateAgentUsername"></organization-select>
        </el-form-item>
        <el-form-item slot="1" label="姓名">
          <el-input v-model="selectForm.payer" type="text"></el-input>
        </el-form-item>
        <el-form-item slot="1" label="电局预留手机号">
          <el-input v-model="selectForm.account" type="text"></el-input>
        </el-form-item>
        <el-form-item slot="2" label="登录状态" prop="loginState">
          <el-select v-model="selectForm.loginState">
            <el-option v-for="item in loginOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item slot="2" label="扫脸认证" prop="processId">
          <el-select v-model="selectForm.authState">
            <el-option v-for="item in authOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item slot="2" label="" prop="processId">
          <div style="width: 200px"></div>
        </el-form-item>
      </p-select-vue>
    </div>

    <div class="p-content">
      <div class="main-top">
        <div class="top-left"></div>
        <div style="padding-bottom: 15px">
          <el-button type="primary" @click="showAdd">新增</el-button>
          <el-button type="primary" @click="importVisible = true">导入</el-button>
        </div>
      </div>

      <el-table ref="recordsTable" v-loading="recordsLoading" :data="records" :header-cell-style="handleHeaderCellStyle" border current-row-key="id" lazy row-key="id" stripe style="width: 100%" @selection-change="handleSelection">
        <el-table-column fixed="left" type="selection" width="50" />
        <el-table-column align="center" label="序号" type="index" width="50">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column label="所属组织" prop="orgName" width="250px"></el-table-column>
        <el-table-column label="姓名" prop="payer"></el-table-column>
        <el-table-column label="手机号码" prop="account" width="140px"></el-table-column>
        <el-table-column label="电子税局密码" prop="password" width="140px">
          <template slot-scope="scope">
            <el-button class="view" type="text" @click.native.prevent="handlePsd(scope.$index, scope.row)"
              ><i v-if="scope.row.isShowPsd" class="el-icon-view"></i>
              <img v-if="!scope.row.isShowPsd" alt="" class="password_img" src="../../../../assets/icon/yj_yc_o.png" />
            </el-button>
            {{ scope.row.isShowPsd ? scope.row.password : '******' }}
          </template>
        </el-table-column>
        <!-- <el-table-column :formatter="defaultedFormatter" label="开票默认" prop="defaulted"></el-table-column> -->
        <el-table-column :formatter="loginFormatter" label="登录状态" prop="loginState" width="150">
          <template slot-scope="props">
            <div v-if="props.row.loginState === 1" style="align-items: center">
              <div style="display: flex; align-items: center">
                <img src="../../../../assets/icon/icon_dot.png" style="width: 18px; height: 18px; padding: 2px" />
                <span style="color: rgb(50, 179, 87)" v-html="loginFormatter(props.row)"></span>
              </div>
            </div>
            <div v-else>
              <div style="display: flex; align-items: center">
                <img src="../../../../assets/icon/icon_dot-grey.png" style="width: 18px; height: 18px; padding: 2px" />
                <span style="color: #dbdbdb" v-html="loginFormatter(props.row)"></span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column :formatter="authFormatter" label="扫脸认证" prop="authState" width="150">
          <template slot-scope="props">
            <div v-if="props.row.authState === 1" style="align-items: center">
              <div style="display: flex; align-items: center">
                <img src="../../../../assets/icon/icon_dot.png" style="width: 18px; height: 18px; padding: 2px" />
                <span style="color: rgb(50, 179, 87)" v-html="authFormatter(props.row)"></span>
              </div>
            </div>
            <div v-else>
              <div style="display: flex; align-items: center">
                <div style="display: flex; align-items: center">
                  <img src="../../../../assets/icon/icon_dot-grey.png" style="width: 18px; height: 18px; padding: 2px" />
                  <span style="color: #dbdbdb" v-html="authFormatter(props.row)"></span>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="短信通知" prop="smsNoticeSwitch">
          <template slot-scope="props">
            <div>{{ props.row.smsNoticeSwitch == 1 ? '开' : '关' }}</div>
          </template>
        </el-table-column>
        <el-table-column label="最后登录时间" prop="loginTime" width="200px"></el-table-column>
        <el-table-column label="最后认证时间" prop="authUpdated" width="200px"></el-table-column>
        <el-table-column fixed="right" label="操作" prop="operate" width="290">
          <template slot-scope="scope">
            <div class="operate-button">
              <el-button size="medium" type="text" @click="auth(scope.row.id)">短信认证</el-button>
              <el-button size="medium" type="text" @click="handelrQrCode(scope.row)">扫码认证</el-button>
              <el-button size="medium" type="text" @click="handleEdit(scope.row)">编辑</el-button>
              <el-button size="medium" type="text" @click="confirmDel(scope.row)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination :current-page.sync="paging.current" :page-size="paging.size" :page-sizes="pageSizes" :total="paging.total" background class="p-page" layout="total, sizes, prev, pager, next" @size-change="handleSizeChange" @current-change="handleCurrentChange"></el-pagination>
    </div>

    <div class="add_num_account">
      <el-dialog :visible.sync="dialogVisible" title="添加数电账号" width="680px">
        <el-form ref="addModal" :model="addModal" :rules="rules" label-width="170px">
          <el-form-item label="所属组织" prop="orgId" style="padding: 10px">
            <el-select v-model="addModal.orgId" placeholder="请选择所属组织"  @change="onOrgChange">
              <el-option v-for="item in organizations" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="纳税人识别号" prop="taxNo" style="padding: 10px">
            <el-input v-model="addModal.taxNo"  placeholder="请输入纳税人识别号" type="text" :disabled=true></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="payer" style="padding: 10px">
            <el-input v-model="addModal.payer" maxlength="8" placeholder="请输入姓名" type="text"></el-input>
          </el-form-item>
          <el-form-item label="电局预留手机号" prop="account" style="padding: 10px">
            <el-input v-model="addModal.account" maxlength="11" placeholder="请输入电局预留手机号" type="text"></el-input>
          </el-form-item>
          <el-form-item label="电子税局密码" prop="password" style="padding: 10px">
            <el-input v-model="addModal.password" maxlength="24" placeholder="请输入电子税局密码" type="text"></el-input>
          </el-form-item>
          <!-- <el-form-item label="开票默认" prop="defaulted" style="padding: 10px">
            <el-switch v-model="addModal.defaulted" active-text="开" inactive-text="关"></el-switch>
            <span style="font-size: 13px; color: darkgrey; margin-left: 60px">(*若设置默认账号，则开票时优先使用默认账号)</span>
          </el-form-item> -->
          <el-form-item label="短信通知" prop="smsNoticeSwitch" style="padding: 10px">
            <el-switch v-model="addModal.smsNoticeSwitch" active-text="开" inactive-text="关" active-value="1" inactive-value="0"></el-switch>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="add('addModal')">确 定</el-button>
        </span>
      </el-dialog>
    </div>

    <div class="add_num_account">
      <el-dialog :visible.sync="updVisible" title="编辑数电账号" width="660px">
        <el-form ref="updModal" :model="updModal" :rules="rules" label-width="170px">
          <el-form-item label="所属组织" prop="orgId" style="padding: 10px">
            <el-select v-model="updModal.orgId" placeholder="请选择所属组织">
              <el-option v-for="item in organizations" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="姓名" prop="payer" style="padding: 10px">
            <el-input v-model="updModal.payer" maxlength="8" placeholder="请输入姓名" type="text"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="account" style="padding: 10px">
            <el-input v-model="updModal.account" maxlength="11" placeholder="请输入手机号" type="text"></el-input>
          </el-form-item>
          <el-form-item label="电子税局密码" prop="password" style="padding: 10px">
            <el-input v-model="updModal.password" maxlength="24" placeholder="请输入电子税局密码" type="text"></el-input>
          </el-form-item>
          <!-- <el-form-item label="开票默认" prop="defaulted" style="padding: 10px">
            <el-switch v-model="updModal.defaulted" active-text="开" inactive-text="关"></el-switch>
            <span style="font-size: 13px; color: darkgrey; margin-left: 60px">(*若设置默认账号，则开票时优先使用默认账号)</span>
          </el-form-item> -->
          <el-form-item label="短信通知" prop="smsNoticeSwitch" style="padding: 10px">
            <el-switch v-model="updModal.smsNoticeSwitch" active-text="开" inactive-text="关" :active-value="1" :inactive-value="0"></el-switch>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="updVisible = false">取 消</el-button>
          <el-button type="primary" @click="upd('updModal')">确 定</el-button>
        </span>
      </el-dialog>
    </div>

    <el-dialog :visible.sync="outerVisible" class="verification_login" title="登录验证" width="30%">
      <el-form :model="verify" :rules="rules" label-width="150px">
        <el-form-item label="电子税务局登录账号" prop="account" style="padding: 32px 10px 30px 35px">
          <el-input v-model="verify.account" style="margin-left: -30px"></el-input>
        </el-form-item>
        <el-form-item v-if="password == 'text'" label="电子税务局登录密码" prop="password" style="padding: 10px 10px 30px 35px; position: relative">
          <el-input v-model="verify.password" :type="password" style="margin-left: -30px; position: relative"></el-input>
          <i class="el-icon-view" @click="handlerElecPassword('password')"></i>
        </el-form-item>
        <el-form-item v-if="password == 'password'" label="电子税务局登录密码" prop="password" style="padding: 10px 10px 30px 35px; position: relative">
          <el-input v-model="verify.password" :type="password" style="margin-left: -30px"></el-input>
          <img alt="" class="password_img" src="../../../../assets/icon/yj_yc_o.png" @click="handlerElecPassword('text')" />
        </el-form-item>
      </el-form>

      <el-dialog :visible.sync="innerVisible" append-to-body title="短信验证" width="30%">
        <div style="padding-bottom: 20px; color: blue">已发送至{{ phone }}短信中</div>
        <el-input v-model="verify.veryCode" clearable placeholder="验证码内容">
          <template slot="prepend">验证码</template>
        </el-input>
        <el-button v-show="timeTrue === true" class="append-btn" type="primary" @click="login">获取验证码</el-button>
        <el-button v-show="timeTrue === false" class="append-btn" type="primary">{{ time }}秒后重新获取</el-button>
        <div slot="footer" class="dialog-footer" style="padding-top: 20px">
          <el-button @click="innerVisible = false">取 消</el-button>
          <el-button :loading="verifyLoading" type="primary" @click="messageVerify">确认</el-button>
        </div>
      </el-dialog>
      <div slot="footer" class="dialog-footer">
        <el-button @click="outerVisible = false">取 消</el-button>
        <el-button :loading="loginLoading" type="primary" @click="login">登录</el-button>
      </div>
    </el-dialog>

    <div class="app_qr_code">
      <el-dialog :show-close="true" :visible.sync="codeDialogVisible" title="APP扫码认证" width="30%" @close="codeDialogVisible = false">
        <el-image :src="qrUrl" style="height: 200px; width: 200px; padding: 10px" />
        <div style="font-size: 16px; margin-top: -15px; padding: 15px">请使用电子税局APP扫描下方二维码，进行身份验证。</div>
        <div style="font-size: 16px; margin-top: -15px; padding: 15px">二维码5分钟内有效。</div>
        <div slot="footer" style="text-align: center">
          <el-button :loading="qrCodegGet" @click="getQrCode">重新获取二维码</el-button>
          <el-button type="primary" @click="getQrResult">确 认</el-button>
        </div>
      </el-dialog>
    </div>

    <el-dialog append-to-body title="导入数电账号" :visible.sync="importVisible" width="500px" :show-close="false">
      <el-form class="income-wrap" ref="merchandiseFileRef" :model="accountFile" v-loading="uploadLoading">
        <div class="edit-select">
          <div style="margin-top: -40px">
            <a :href="staticFile.equipmentImportXls.url" :download="staticFile.equipmentImportXls.name">{{ staticFile.equipmentImportXls.name }}</a>
          </div>
          <div class="edit-select-item">
            <el-input v-model="accountFile.fileName" placeholder="文件名称.xlsx 或 文件名称.xls" style="width: 300px"></el-input>
            <el-upload accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" action="#" :multiple="false" :show-file-list="false" :on-exceed="handleExceed" :on-change="uploadFile">
              <el-button type="default" icon="el-icon-upload2"> 上传文件 </el-button>
            </el-upload>
          </div>
        </div>
        <div class="edit-select" style="margin-top: 10px; padding: 0 20px 0 20px">
          <div class="edit-select-foot">
            <el-button class="el-button-ext" @click="uploadClose">取消</el-button>
            <el-button class="el-button-ext" @click="uploadAccount" type="primary">导入 </el-button>
          </div>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import PHeaderVue from '@/components/pdt/PHeader.vue';
import PSelectVue from '@/components/pdt/PSelect.vue';
import { changeMethods, dateOption, pagingMethods, tableStyle } from '@/util/mixins';
import OrganizationSelect from '@/components/widgets/OrganizationSelect.vue';
import { eAccountGet } from '@/service/rpa/e-account';
import RegexLib from '@/assets/js/regex-lib';
import { getQrResult, qrCode, rpaAdd, rpaAuthConfirm, rpaAuthGet, rpaAuthLogin, rpaAuthPage, rpaDelete, rpaGetDeFaultedByOrgId, rpaUpdate, rpaAccountUpload } from '@/service/rpa/rpa-auth';
import StaticFile from '@/assets/js/static-file';

export default {
  components: { OrganizationSelect, PSelectVue, PHeaderVue },
  mixins: [tableStyle, pagingMethods, dateOption, changeMethods],
  data() {
    return {
      staticFile: StaticFile,
      qrCodegGet: false,
      phone: '',
      orgId: '',
      loginLoading: false,
      verifyLoading: false,
      codeDialogVisible: false,
      outerVisible: false,
      innerVisible: false,
      verify: {
        orgId: '',
        account: '',
        password: '',
        veryCode: ''
      },
      organizations: [],
      addModal: {
        orgId: '',
        account: '',
        password: '',
        payer: '',
        defaulted: false,
        smsNoticeSwitch: 0
      },
      updModal: {
        orgId: '',
        account: '',
        password: '',
        payer: '',
        defaulted: false,
        smsNoticeSwitch: 0
      },
      dialogVisible: false,
      updVisible: false,
      password: 'password',
      isShow: false,
      qrUrl: '',
      rzid: '',
      timeTrue: true,
      time: 0,
      uploadLoading: false,
      accountFile: {
        fileName: '',
        file: {}
      },
      importVisible: false,
      authOptions: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 1,
          label: '已认证'
        },
        {
          value: 0,
          label: '未认证'
        }
      ],
      loginOptions: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 1,
          label: '已登录'
        },
        {
          value: 0,
          label: '未登录'
        }
      ],
      selectForm: {
        orgName: '',
        id: '',
        loginState: '',
        authState: '',
        payer: '',
        account: '',
        orgId: ''
      },
      record: {
        orgId: '',
        orgName: ''
      },
      isCloseCondition: true,
      recordsLoading: false,
      records: [],
      paging: {
        current: 1,
        size: 15,
        total: 0,
        searchCount: true,
        pages: 1
      },
      qrCodeId: '',
      selections: [],
      pageSizes: [15, 30, 50, 100],
      rules: {
        orgId: [
          { required: true, message: '请选择所属组织' }
          // {pattern: RegexLib.Username, message: '须是4~20位字母、数字、-_@.', trigger: 'blur'}
        ],
        payer: [
          { required: true, message: '请输入姓名' }
          // {pattern: RegexLib.Username, message: '须是4~20位字母、数字、-_@.', trigger: 'blur'}
        ],
        account: [
          { required: true, message: '请输入手机号' },
          { pattern: RegexLib.Phone, message: '请输入正确的手机号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入电子税局登录密码' }
          // {pattern: RegexLib.Password, message: '须是 4~30 位英文/数字/-@_!$#', trigger: 'blur'}
        ],
        // defaulted: [
        //   { required: true, message: '选择是否默认开票' }
        //   // {pattern: RegexLib.Password, message: '须是 4~30 位英文/数字/-@_!$#', trigger: 'blur'}
        // ]
      }
    };
  },
  mounted() {
    this.query();
    this.organizations = this.$store.state.user.organizations;
    console.log(JSON.stringify(this.organizations, null, 2));
  },
  watch: {
    'record.orgId': {
      handler: 'generateAgentUsername',
      deep: true,
      immediate: true
    }
  },
  methods: {
    uploadClose() {
      this.importVisible = false;
      this.accountFile.file = null;
      this.accountFile.fileName = '';
    },
    /* 导入保存 */
    async uploadAccount() {
      console.log(this.accountFile);
      if (!this.accountFile.file.name) {
        this.toast('请选择要上传文件！', 'warning');
        return;
      }
      this.confirmImport();
    },
    //处理确认
    async confirmImport() {
      let formData = new FormData();
      formData.append('file', this.accountFile.file);

      this.uploadLoading = true;
      const res = await rpaAccountUpload(formData);
      this.uploadLoading = false;
      if (res.success) {
        this.toast('导入成功！', 'success');
        this.query();
      }
      this.accountFile.file = null;
      this.accountFile.fileName = '';
    },
    /* 上传错误捕获 */
    handleExceed() {
      this.toast('上传文件出错', 'error');
    },
    uploadFile(file) {
      this.accountFile.fileName = file.name;
      this.accountFile.file = file.raw;
    },
    async getQrResult() {
      const { success } = await getQrResult(this.qrCodeId + '/' + this.rzid);
      if (success) {
        this.toast('认证成功', 'success');
      } else {
        this.toast('认证失败', 'error');
      }
      await this.query();
      this.codeDialogVisible = false;
    },
    loginFormatter(row) {
      return row.loginState === 1 ? '已登录' : '未登录';
    },
    authFormatter(row) {
      return row.authState === 1 ? '已认证' : '未认证';
    },
    defaultedFormatter(row) {
      return row.defaulted ? '是' : '否';
    },
    handlePsd(idx) {
      this.records[idx].isShowPsd = !this.records[idx].isShowPsd;
      this.isShow = this.records[idx].isShowPsd;
    },
    showAdd() {
      this.addModal = {
        orgId: '',
        account: '',
        password: '',
        payer: '',
        defaulted: false
      };
      this.dialogVisible = true;
    },
    acquire() {
      this.timeTrue = false;
      this.time = 60;
      var setTimeoutS = setInterval(() => {
        this.time--;
        if (this.time <= 0) {
          clearInterval(setTimeoutS);
          this.timeTrue = true;
        }
      }, 1000);
    },

    //表单重置
    reset() {
      this.record.orgId = '';
      this.selectForm = {
        orgName: '',
        id: '',
        loginState: '',
        authState: '',
        payer: '',
        account: '',
        orgId: ''
      };
    },
    //查询
    async query() {
      console.log(this.selectForm);
      let param = {};
      if (this.record.orgId) {
        param.orgId = this.record.orgId;
      }
      if (this.selectForm.loginState !== '') {
        param.loginState = this.selectForm.loginState;
      }
      if (this.selectForm.authState !== '') {
        param.authState = this.selectForm.authState;
      }
      if (this.selectForm.payer) {
        param.payer = this.selectForm.payer;
      }
      if (this.selectForm.account) {
        param.account = this.selectForm.account;
      }
      param.current = this.paging.current;
      param.size = this.paging.size;
      this.recordsLoading = true;
      this.paging.searchCount = true;
      const { success, data } = await rpaAuthPage(param);
      if (success) {
        this.records = data.records.map((item) => {
          item.isShowPsd = false;
          return item;
        });
        this.paging.total = data.total;
      }
      this.recordsLoading = false;
    },
    close() {
      this.isCloseCondition = !this.isCloseCondition;
    },
    handleSelection(value) {
      this.selections = value;
    },
    // 切换分页条数
    handleSizeChange(val) {
      this.paging.size = val;
      this.query();
    },

    handleCurrentChange(val) {
      this.paging.current = val;
      this.query();
    },
    // 补全助手名称
    async generateAgentUsername() {},
    handlerElecPassword(ico) {
      if (ico == 'text') {
        this.password = 'text';
      } else {
        this.password = 'password';
      }
    },
    //编辑
    async handleEdit(row) {
      const { success, data } = await rpaAuthGet(row.id);
      if (success) {
        this.updModal = data;
        this.updVisible = true;
      }
    },
    handleCancelDetail() {
      this.detailVisible = false;
    },

    async auth(id) {
      await this.render(id);
      this.outerVisible = true;
    },
    async login() {
      this.loginLoading = true;
      rpaAuthLogin(this.verify).then((res) => {
        // console.log(res)
        if (res.code === '1') {
          this.toast(res.message, 'success');
          this.outerVisible = false;
        } else if (res.code === '0' && res.data) {
          this.phone = res.data.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
          this.innerVisible = true;
          this.acquire();
        } else {
          this.toast(res.message, 'error');
        }
        this.logining = false;
        this.loginLoading = false;
        this.query();
      });
    },
    async messageVerify() {
      this.verifyLoading = true;
      const { success } = await rpaAuthConfirm(this.verify);
      if (success) {
        this.innerVisible = false;
        this.outerVisible = false;
        this.veryCode = '';
        this.toast('认证完成!', 'success');
        await this.query();
      }

      this.verifyLoading = false;
    },
    async render(id) {
      this.verify = {
        id: id,
        orgId: '',
        account: '',
        password: ''
      };
      const { data } = await rpaAuthGet(id);
      this.verify.orgId = data.orgId;
      this.verify.password = data.password;
      this.verify.account = data.account;
    },
    async getQrCode() {
      const { success, data } = await qrCode({ id: this.qrCodeId });
      if (success) {
        this.qrUrl = data.qrCode;
        this.rzid = data.rzid;
        this.codeDialogVisible = true;
        this.qrCodegGet = false;
      }
    },
    async handelrQrCode(row) {
      this.qrCodegGet = true;
      this.qrCodeId = row.id;
      this.orgId = row.orgId;
      await this.getQrCode();
    },
    //详细
    handleDetail(row) {
      this.recordId = row.id;
      this.detailVisible = true;
    },
    onOrgChange(){
      // 当组织选择改变时，查找对应的纳税人识别号
      const selectedOrg = this.organizations.find(org => org.id === this.addModal.orgId);
      if (selectedOrg) {
        this.addModal.taxNo = selectedOrg.taxNo;
      }
    },

    getDetail(id) {
      this.recordLoading = true;
      eAccountGet(id)
        .then((res) => {
          if (res.success) {
            this.record = res.data;
          }
          this.recordLoading = false;
        })
        .catch(() => {
          this.recordLoading = false;
        });
    },

    confirmDel(row) {
      this.$confirm('此操作将删除该条数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { success } = await rpaDelete(row.id);
        if (success) {
          this.toast('删除成功', 'success');
          await this.query();
        }
      });
    },
    async upd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.handlerUpd();
          // alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    async updSubmit() {
      console.log(this.updModal, '----this.updModal)----');
      const { success } = await rpaUpdate(this.updModal);
      if (success) {
        this.updModal = {
          id: '',
          orgId: '',
          account: '',
          password: '',
          payer: '',
          defaulted: false,
          smsNoticeSwitch: false
        };
        this.updVisible = false;
        this.toast('编辑成功', 'success');
        await this.query();
      }
    },
    async handlerUpd() {
      const { success, data } = await rpaGetDeFaultedByOrgId(this.updModal.orgId);
      if (this.updModal.defaulted && data) {
        this.$confirm('已存在开票默认的数电账号，是否更改？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.updSubmit();
        });
      } else {
        await this.updSubmit();
      }
    },
    async handlerAdd() {
      const { success, data } = await rpaGetDeFaultedByOrgId(this.addModal.orgId);
      if (this.addModal.defaulted && data) {
        this.$confirm('已存在开票默认的数电账号，是否更改？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.addSubmit();
        });
      } else {
        await this.addSubmit();
      }
    },
    async add(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.handlerAdd();
          // alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    async addSubmit() {
      const { success } = await rpaAdd(this.addModal);
      if (success) {
        this.addModal = {
          orgId: '',
          account: '',
          password: '',
          payer: '',
          defaulted: false
        };
        this.dialogVisible = false;
        this.toast('添加成功', 'success');
        await this.query();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-select {
  .edit-select-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px 0 20px;
  }

  .edit-select-item:first-child {
    margin-bottom: 50px;
  }

  .edit-select-item:last-child {
    margin-top: 20px;
  }

  .edit-select-foot {
    display: flex;
    height: 60px;
    width: 100%;
    justify-content: flex-end;
  }
}

.invoice-dialog {
  div {
    margin-bottom: 12px;
  }

  span {
    width: 240px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 20px;
  }

  .el-image {
    width: 200px;
    height: 200px;
  }
}

.content-main {
  min-height: calc(100% - 170px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ::v-deep .el-button {
    padding: 0;
  }
}

.append-btn {
  width: 135px;
}

.operate-button {
  display: flex;
  justify-content: center;

  .el-button {
    padding: 0;
    margin-right: 10px;
  }
}

.p-header {
  background-color: #fff;
}

.p-select {
  padding: 24px;

  ::v-deep .el-form-item__label {
    text-align: left;
  }
}

.p-content {
  padding-top: 20px;

  ::v-deep .el-checkbox__label {
    display: none;
  }

  .table-btn {
    padding: 0 !important;
  }
}

.view {
  position: absolute;
  top: 10px;
  left: 88px;

  .password_img {
    width: 16px;
    height: 16px;
    margin-top: 8px;
  }
}

.verification_login {
  ::v-deep .el-dialog__body {
    padding: 0 0 0 0;
  }

  ::v-deep .el-input .el-input__clear {
    color: #00a0ff;
  }

  .el-icon-view {
    position: absolute;
    top: 10px;
    right: 12vh;
  }

  .password_img {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 8px;
    right: 12vh;
  }

  ::v-deep .el-form-item__content {
    position: relative;
  }
}

.app_qr_code {
  ::v-deep .el-dialog__header {
    padding: 20px 20px 0px;
  }

  ::v-deep .el-dialog .el-dialog__body {
    padding: 20px 0px 0px 0px;
  }
}

.add_amend_style {
  ::v-deep .el-dialog .el-dialog__body {
    padding: 10px 0 0;
  }
}
.add_num_account {
  ::v-deep .el-dialog .el-dialog__body {
    padding: 10px 0 0 0;
    text-align: left;
  }
  ::v-deep .el-form-item__error {
    top: 30px;
  }
}
</style>
