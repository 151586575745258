import {del, get, post, put} from '../index';


export const eAccountAdd = (data) => {
    return post('/v2/rpa/add', data).then((res) => res);
};

export const eAccountUpdate = (data) => {
    return put('/v2/rpa/update', data).then((res) => res);
};

export const eAccountPage = (data) => {
    return post('/v2/rpa/page', data).then((res) => res);
};

export const eAccountDel = (data) => {
    return del('/v2/rpa/delete/' + data).then((res) => res);
};
export const eAccountGet = (data) => {
    return get('/v2/rpa/get/' + data).then((res) => res);
};
export const getDeFaultedByOrgId = (data) => {
    return get('/v2/rpa/getDeFaultedByOrgId/' + data).then((res) => res);
};

export const eAccountLogin = (data) => {
    return post('/v2/rpa/auth/login/', data, {timeout: 5 * 60 * 1000}).then((res) => res);
};
export const eAccountConfirm = (data) => {
    return post('/v2/rpa/auth/confirm/', data).then((res) => res);
};

export const eAccountQrCode = (data) => {
    return post('/v2/rpa/auth/qrCode/', data).then((res) => res);
};
